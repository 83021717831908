import React, { useState, useEffect } from "react";
import "./SearchBar.css";

const SearchBar = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  allApplications,
  selectedApplicationIds,
  setSelectedApplicationIds,
  allKnowledgeBases,
  selectedKnowledgeBaseIds,
  setSelectedKnowledgeBaseIds,
}) => {
  const [tempSelected, setTempSelected] = useState(selectedApplicationIds);
  const [tempKBSelected, setTempKBSelected] = useState(
    selectedKnowledgeBaseIds
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isKBDropdownOpen, setIsKBDropdownOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [isKBSelectAll, setIsKBSelectAll] = useState(true);

  const buttonText = sessionStorage.getItem("hideTraffic") === "true" ? "Knowledge Base" : "Content Section";

  // Update tempSelected when selectedApplicationIds changes
  useEffect(() => {
    setTempSelected(selectedApplicationIds);
  }, [selectedApplicationIds]);

  // Update tempKBSelected when selectedKnowledgeBaseIds changes
  useEffect(() => {
    setTempKBSelected(selectedKnowledgeBaseIds);
  }, [selectedKnowledgeBaseIds]);

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handleCheckboxChange = (appId) => {
    setTempSelected((prev) => {
      if (prev.includes(appId)) {
        return prev.filter((id) => id !== appId);
      }
      return [...prev, appId];
    });
  };

  const handleKBCheckboxChange = (kbId) => {
    setTempKBSelected((prev) => {
      if (prev.includes(kbId)) {
        return prev.filter((id) => id !== kbId);
      }
      return [...prev, kbId];
    });
  };

  const handleApply = () => {
    setSelectedApplicationIds(tempSelected);
    setIsDropdownOpen(false);
  };

  const handleKBApply = () => {
    setSelectedKnowledgeBaseIds(tempKBSelected);
    setIsKBDropdownOpen(false);
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setTempSelected([]);
    } else {
      setTempSelected([...allApplications]);
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleKBSelectAll = () => {
    if (isKBSelectAll) {
      setTempKBSelected([]);
    } else {
      setTempKBSelected([...allKnowledgeBases]);
    }
    setIsKBSelectAll(!isKBSelectAll);
  };

  return (
    <div className="search-bar-container d-flex justify-content-between align-items-center">
      <div className="date-range-selector d-flex align-items-center">
        <span className="me-2" style={{ color: "#0A4650" }}>
          Select period:
        </span>
        <input
          type="date"
          className="form-control me-2"
          value={startDate.toISOString().split("T")[0]}
          onChange={handleStartDateChange}
        />
        <span className="me-2" style={{ color: "#0A4650" }}>
          to
        </span>
        <input
          type="date"
          className="form-control"
          value={endDate.toISOString().split("T")[0]}
          onChange={handleEndDateChange}
        />

        <div className="dropdown ms-2">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            Applications ({selectedApplicationIds.length})
          </button>
          <div
            className={`dropdown-menu p-2 ${isDropdownOpen ? "show" : ""}`}
            style={{ minWidth: "350px" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="dropdown-header">Select Applications</h6>
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={handleSelectAll}
              >
                {isSelectAll ? "Deselect All" : "Select All"}
              </button>
            </div>
            <div className="dropdown-divider"></div>
            {allApplications.map((appId) => (
              <div key={appId} className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={appId}
                  checked={tempSelected.includes(appId)}
                  onChange={() => handleCheckboxChange(appId)}
                />
                <label className="form-check-label" htmlFor={appId}>
                  {appId.replace(/-/g, " ")}
                </label>
              </div>
            ))}
            <div className="dropdown-divider"></div>
            <button className="btn btn-primary w-100" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>

        <div className="dropdown ms-2">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            onClick={() => setIsKBDropdownOpen(!isKBDropdownOpen)}
          >
            {buttonText} ({selectedKnowledgeBaseIds.length})
          </button>
          <div
            className={`dropdown-menu p-2 ${isKBDropdownOpen ? "show" : ""}`}
            style={{ minWidth: "350px" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="dropdown-header">Select {buttonText}</h6>
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={handleKBSelectAll}
              >
                {isKBSelectAll ? "Deselect All" : "Select All"}
              </button>
            </div>
            <div className="dropdown-divider"></div>
            {allKnowledgeBases.map((kbId) => (
              <div key={kbId} className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={kbId}
                  checked={tempKBSelected.includes(kbId)}
                  onChange={() => handleKBCheckboxChange(kbId)}
                />
                <label className="form-check-label" htmlFor={kbId}>
                  {kbId.replace(/-/g, " ")}
                </label>
              </div>
            ))}
            <div className="dropdown-divider"></div>
            <button className="btn btn-primary w-100" onClick={handleKBApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
