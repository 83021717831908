import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";

const UpdateProfilePage = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    password: "",
    confirmPassword: "",
    profile_picture: ""
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const username = sessionStorage.getItem("username");
    
    if (!token || !username) {
      navigate("/login");
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/v1/user/${username}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (data.success) {
          setUserData({
            firstname: data.firstname,
            lastname: data.lastname,
            password: "",
            confirmPassword: "",
            profile_picture: data.profile_picture
          });
        } else {
          setError("Failed to load user data");
        }
      } catch (err) {
        setError("Server error. Please try again.");
      }
    };

    fetchUserData();
  }, [BACKEND_URL, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value
    }));
    
    if (name === "password" || name === "confirmPassword") {
      const otherField = name === "password" ? "confirmPassword" : "password";
      setPasswordMatch(value === "" || userData[otherField] === "" || value === userData[otherField]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    
    if (userData.password !== userData.confirmPassword) {
      setError("Passwords do not match");
      setPasswordMatch(false);
      return;
    }
    
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const username = sessionStorage.getItem("username");

    try {
      const response = await fetch(`${BACKEND_URL}/api/v1/user/${username}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          firstname: userData.firstname,
          lastname: userData.lastname,
          password: userData.password,
          profile_picture: userData.profile_picture
        }),
      });

      const data = await response.json();

      if (data.success) {
        // Update session storage with new values
        sessionStorage.setItem("firstname", userData.firstname);
        sessionStorage.setItem("lastname", userData.lastname);
        sessionStorage.setItem("profile_picture", userData.profile_picture);
        
        setSuccess("Profile updated successfully");
      } else {
        setError(data.message || "Failed to update profile");
      }
    } catch (err) {
      setError("Server error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <SideBar current_page={"profile"} />
      <div className="main-page">
        <TopNavBar />
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-md-8 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h3 className="text-center mb-4">Update Profile</h3>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="alert alert-success" role="alert">
                      {success}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="firstname" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        value={userData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastname" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        value={userData.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={userData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${!passwordMatch ? 'is-invalid' : ''}`}
                        id="confirmPassword"
                        name="confirmPassword"
                        value={userData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                      {!passwordMatch && (
                        <div className="invalid-feedback">
                          Passwords do not match
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="profile_picture" className="form-label">
                        Profile Picture URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="profile_picture"
                        name="profile_picture"
                        value={userData.profile_picture}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <div className="d-flex justify-content-center">
                        <img 
                          src={userData.profile_picture} 
                          alt="Profile Preview" 
                          className="img-thumbnail" 
                          style={{ maxWidth: '150px', maxHeight: '150px' }}
                        />
                      </div>
                    </div>
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Update Profile"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfilePage;