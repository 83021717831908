import React, { useState, useMemo, useEffect } from "react";
import SingleColumnTable from "./SingleColumnTable";

const QueryFilterTabs = ({
  data = [],
  isLoaded = true,
  error = null,
  columnKey = "value",
  columnTitle = "Column",
  tooltipText = null,
}) => {
  // Get unique cluster names for tabs
  const uniqueClusterNames = useMemo(() => {
    return [...new Set(data.map((q) => q.cluster_name))];
  }, [data]);

  // State for selected cluster
  const [selectedCluster, setSelectedCluster] = useState(null);

  // Update selected cluster when data is loaded
  useEffect(() => {
    if (isLoaded && uniqueClusterNames.length > 0) {
      setSelectedCluster((prevSelected) =>
        !prevSelected || !uniqueClusterNames.includes(prevSelected)
          ? uniqueClusterNames[0]
          : prevSelected
      );
    }
  }, [isLoaded, uniqueClusterNames]);

  // Filter queries based on selected cluster
  const filteredQueries = useMemo(() => {
    if (!isLoaded || error) {
      return [];
    }

    const effectiveCluster = selectedCluster || uniqueClusterNames[0];
    return data.filter((q) => q.cluster_name === effectiveCluster);
  }, [data, selectedCluster, isLoaded, error, uniqueClusterNames]);

  const handleTabChange = (clusterName) => {
    setSelectedCluster(clusterName);
  };

  return (
    <div className="container-fluid p-0" style={{ backgroundColor: "#F5F5F5" }}>
      <div className="row">
        {/* Left side vertical tabs */}
        <div className="col-4 pe-0" style={{ border: "5px" }}>
          <h6 className="fw-bold ps-3" style={{ paddingTop: "20px" }}>
            Query Groups (Clusters)
          </h6>
          <div className="nav flex-column nav-pills" role="tablist">
            {uniqueClusterNames.map((clusterName) => (
              <button
                key={clusterName}
                className={`nav-link text-start border-0 rounded-0`}
                onClick={() => handleTabChange(clusterName)}
                disabled={!isLoaded || error}
                style={{
                  backgroundColor:
                    selectedCluster === clusterName ? "#fff" : "transparent",
                  color: "#666",
                  opacity: !isLoaded || error ? 0.5 : 1,
                  padding: "10px 15px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              >
                {clusterName}
              </button>
            ))}
          </div>
        </div>

        {/* Right side content */}
        <div className="col-8 ps-0">
          <SingleColumnTable
            key={selectedCluster}
            data={filteredQueries}
            isLoaded={isLoaded}
            error={error}
            columnKey={columnKey}
            columnTitle={columnTitle}
            tooltipText={tooltipText}
            defaultNumberOfRows={5}
            noDataMessage="Congratulations. Your content coverage is solid! 🚀"
          />
        </div>
      </div>
    </div>
  );
};

export default QueryFilterTabs;
