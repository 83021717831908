import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";

const consoleError = console.error;
console.error = function(message, ...args) {
  if (typeof message === 'string' && message.includes('ResizeObserver loop')) {
    // Ignore ResizeObserver loop errors
    return;
  }
  consoleError(message, ...args);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);